// Job listing for bulk apply *********************************************************************
// pathname: /v1/job-operation/bulk-apply-feed
// ************************************************************************************************

import {
  useSwrApi,
  ApiJobListCards,
} from '@hooks/useSwrApi';

// Interface
// ********************************************
interface SwrBulkApplyFeedProps {
  shouldFetch?: boolean;
  jobSlug:string;
  locale?: string;
  serverSideData?: ApiJobListCards;
  urlParams?: string;
}


/**
 * @description SWR: fetch job list for bulk apply.
 *
 * * **shouldFetch**: boolean. A flag indicating whether to start fetching the feed.
 * * **locale**: string. What locale to use for the request.
 * * **serverSideData**: ApiJobListCards, optional. The data we got from the server side request (if we do it). Default undefined.
 * * **urlParams**: '&sort=relevant', optional. Default empty string.
 */
export const useSwrBulkApplyFeed = (props: SwrBulkApplyFeedProps) => {
  // Props destructuring
  // ********************************************
  const {
    shouldFetch = true,
    locale,
    serverSideData,
    urlParams = '',
    jobSlug,
  } = props;


  // Get data from API
  // ********************************************
  const {
    data,
    error,
    isValidating,
    isLoading,
    mutate,
  } = useSwrApi<ApiSchema<'JobResult2'>>({
    url: '/v3/jobs/similar',
    shouldFetch,
    locale,
    urlParams: `${jobSlug ? `?jobSlug=${jobSlug}` : ''}${urlParams}`,
    serverSideData,
    apiOptions: {
      revalidateOnFocus: false,
      dedupingInterval: 1000 * 60, // 1 minute
    },
    toastsOptions: {
      showErrorToast: true,
    },
  });


  // Return data
  // ********************************************
  return {
    data,
    error,
    isValidating,
    isLoading,
    mutate,
  };
};
