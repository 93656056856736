import {
  ChangeEvent, useRef, useState,
} from 'react';
import { useTranslation, Trans } from 'next-i18next';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import {
  Button, ImageBj, Modal, bjToast, Anchor, Spinner,
} from '@components/common';
import uploadExistingCvImage from '@images/profile/img-file-upload-cv-upload.svg';
import { ArrowUpTrayIcon } from '@heroicons/react/24/solid';
import { ACCEPTED_MIME_TYPES } from '@utils/file/file-types';
import { useSwrJobEasyApply } from '@hooks/useSwrApi/endpoints/job/useSwrJobEasyApply';
import { setCookie } from 'cookies-next';
import { JOB_APPLY_COOKIE_NAME, JOB_APPLY_WEB_TYPE } from 'src/constants';

// Interface
// ******************************************
interface EasyApplyModalProps {
  jobSlug: string,
}

/**
 * @description Easy Apply (apply with resume) modal component.
 *
 * - **jobSlug** - string - The job slug.
 */
export const EasyApplyModal = NiceModal.create((props: EasyApplyModalProps) => {
  // Destructure props
  const {
    jobSlug,
  } = props;

  // Translation
  const { t } = useTranslation('common');

  // Use a hook to manage the modal state
  const modal = useModal();

  // File Input Ref
  const fileInputRef = useRef<HTMLInputElement>(null);

  // Set state for error message
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  // Close modal
  // *****************************************************
  const closeModal = (statusCode?: number) => {
    setErrorMessage(undefined);

    if (statusCode) {
      modal.resolve(statusCode);
    }

    void modal.hide();
  };

  // SWR Mutation
  // *****************************************************
  const { trigger, isMutating } = useSwrJobEasyApply({
    slug: jobSlug,
    toastsOptions: {
      successToastText: t('job.apply.react.toast.success'),
      showErrorToast: false,
    },
    successCallback: () => {
      closeModal(200);
    },
    errorCallback: (errorObj) => {
      if (!errorObj || !errorObj?.errors?.length) {
        return;
      }

      const error = errorObj?.errors?.[0];
      bjToast.error(error?.message);

      // `454` - User already registered - Show this error message in the modal with a login button
      // Otherwise, close the modal and send the error.code to the parent component
      if ([454].some((code) => code === error?.code)) {
        setErrorMessage(error?.message);
      } else {
        closeModal(error?.code);
      }
    },
  });


  // Trigger SWR Mutation if file is valid.
  // *****************************************************
  const handleUpload = (file: File | undefined) => {
    if (!file) {
      return;
    }

    // Check for file size: max 10MB
    if (file.size > 10 * 1024 * 1024) {
      bjToast.error(t('error.maxsize'));
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('appType', JOB_APPLY_WEB_TYPE.toString());

    void trigger(formData);
  };

  // Handle file change
  // *****************************************************
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    handleUpload(selectedFile);
  };

  // Set cookie for job-apply.
  // *****************************************************
  const setJobApplyCookie = () => {
    // Set cookie for job-apply. By default, it is a session cookie
    void setCookie(JOB_APPLY_COOKIE_NAME, JSON.stringify({ jobSlug }));
  };

  // Render component
  // *****************************************************
  return (
    <Modal
      isOpen={modal.visible}
      onClose={
        /* Pass an empty function because we don't want to close the modal on backdrop click or on ESC key. */
        () => {}
      }
      size="sm"
    >
      <Modal.Header content={t('modal.cv_upload.title')} onClose={closeModal} />
      <Modal.Body>
        <p>
          {t('modal.cv_upload.text_unsigned')}
        </p>
        <ImageBj src={uploadExistingCvImage as string} priority sizes="20%" alt="Onboarding" className="mx-auto" />

        {/* Show uploading spinner */}
        {
          isMutating && (
            <div className="my-6 flex h-40 flex-col justify-center gap-2 text-center">
              <Spinner />
              <p className="text-ink-medium">{t('cv.upload.processing')}</p>
            </div>
          )
        }

        {/* Show error message */}
        {
          errorMessage && (
            <div className="my-6 flex flex-col items-center justify-center gap-2 text-center">
              {errorMessage}
              <Anchor
                color="secondary"
                href="login"
                symfonyRoute
                onClick={setJobApplyCookie}
              >
                { t('login_or_register.bt.sign_in_continue')}
              </Anchor>
            </div>
          )
        }

        {/* Shows details and button for uploading cv */}
        {
          !isMutating && !errorMessage && (
            <>
              <div className="my-6 flex w-full justify-center">
                <Button color="secondary" className="tr_easy_apply_upload flex gap-2" onClick={() => fileInputRef.current?.click()}>
                  <ArrowUpTrayIcon className="size-5" />
                  {t('resume.upload_resume_modal.btn.upload')}
                </Button>
              </div>
              <input type="file" onChange={handleFileChange} ref={fileInputRef} hidden accept={ACCEPTED_MIME_TYPES.join(', ')} />
              <p className="text-center text-xs text-ink-medium">{t('modal.cv_upload.text_bottom')}</p>
              <p className="mt-4 text-center text-xs text-ink-medium">
                <Trans
                  i18nKey="register.react.upload.label.terms"
                  components={{ anchorlink: <Anchor className="text-sm" styling="text" href="gdpr_terms_and_conditions" symfonyRoute target="_blank" /> }}
                />
              </p>
              <div className="mt-4 flex flex-col items-center gap-4 border-t pt-4 lg:flex-row lg:justify-center">
                <Anchor styling="text" href="app_register_user" symfonyRoute>
                  { t('modal.cv_upload.no_cv_register')}
                </Anchor>
                <Anchor styling="text" href="login" symfonyRoute onClick={setJobApplyCookie}>
                  { t('modal.cv_upload.i_have_an_account')}
                </Anchor>
              </div>
            </>
          )
        }
      </Modal.Body>
    </Modal>
  );
});
